


const learningData = [

{ 
    id: 9,
    course_name: "Architecting with Google Kubernetes Engine",
    organization: "https://media.licdn.com/dms/image/C4D0BAQGexnfBxeEG-g/company-logo_100_100/0/1630530042036/coursera_logo?e=1719446400&v=beta&t=ToFg6qTw5GORSwhlJleTBwpUHGZHX2HjLfnbac3ULPw",
    date: "SNDP Yogam Arts & Science College Pulpally, Wayanad, Kerala",
    certDate: "Issued: May 2023",
    extra_class: "",
    linkto: "",
    tooltips: ""
},
{ 
    id: 8,
    course_name: "Google Cloud Fundamentals: Core Infrastructure",
    organization: "https://media.licdn.com/dms/image/C4D0BAQHTIj3QmQ5N0A/company-logo_100_100/0/1656443889325?e=1719446400&v=beta&t=Dr0_gDnoDlPJfJqSQ_eHdmqMeTbwpz4UOQC6H28V4qU",
    date: "SNDP Yogam Arts & Science College Pulpally, Wayanad, Kerala",
    certDate: "Issued: May 2023",
    extra_class: "",
    linkto: "",
    tooltips: ""
},
{ 
    id: 7,
    course_name: "Preparing for Google Cloud Certification: Cloud Engineer",
    organization: "https://media.licdn.com/dms/image/C4D0BAQHTIj3QmQ5N0A/company-logo_100_100/0/1656443889325?e=1719446400&v=beta&t=Dr0_gDnoDlPJfJqSQ_eHdmqMeTbwpz4UOQC6H28V4qU",
    date: "SNDP Yogam Arts & Science College Pulpally, Wayanad, Kerala",
    certDate: "Issued: May 2023",
    extra_class: "",
    linkto: "",
    tooltips: ""
},
{ 
    id: 6,
    course_name: "Ultimate AWS Certified Solutions Architect Associate SAA-C02",
    organization: "https://logospng.org/download/udemy/udemy-4096.png",
    date: "SNDP Yogam Arts & Science College Pulpally, Wayanad, Kerala",
    certDate: "Issued: Aug 2022",
    extra_class: "",
    linkto: "",
    tooltips: ""
},
{ 
    id: 5,
    course_name: "Fundamentals of Red Hat Enterprise Linux",
    organization: "https://media.licdn.com/dms/image/C4E0BAQEto-TydTTIfQ/company-logo_100_100/0/1630583759577/red_hat_logo?e=1719446400&v=beta&t=VUW2mIlKYsej8eqtq10I4DOVMq2l2QtpRIEqfP8RqiU",
    date: "SNDP Yogam Arts & Science College Pulpally, Wayanad, Kerala",
    certDate: "Issued: Jul 2022",
    extra_class: "",
    linkto: "",
    tooltips: ""
},
{ 
    id: 4,
    course_name: "AWS Beginners Course",
    organization: "https://media.licdn.com/dms/image/C4D0BAQHIXUJjedkclA/company-logo_100_100/0/1645442188611/edureka_logo?e=1719446400&v=beta&t=FNM-22tFCLItc5QLmuswSyh-rfmzFg980VIY1o60Y0U",
    date: "SNDP Yogam Arts & Science College Pulpally, Wayanad, Kerala",
    certDate: "Issued: May 2022",
    extra_class: "",
    linkto: "",
    tooltips: ""
},
{ 
    id: 3,
    course_name: "AWS Cloud Practitioner Essentials",
    organization: "https://media.licdn.com/dms/image/C560BAQER_QnUTXrPJw/company-logo_100_100/0/1670264051233/amazon_web_services_logo?e=1719446400&v=beta&t=ttAopDZC2QJQYYb6hSodV0zzN21KC79GKs1Q8iq7Rgw",
    date: "SNDP Yogam Arts & Science College Pulpally, Wayanad, Kerala",
    certDate: "Issued: May 2022",
    extra_class: "",
    linkto: "",
    tooltips: ""
},
{ 
    id: 2,
    course_name: "Cloud foundation",
    organization: "https://media.licdn.com/dms/image/C560BAQF40lFj20_wxA/company-logo_100_100/0/1630649587188/great_learning_logo?e=1719446400&v=beta&t=qIqpOPLxwsIq-EIEBs4ETnvRwOfkYclyVZwIexBZozM",
    date: "SNDP Yogam Arts & Science College Pulpally, Wayanad, Kerala",
    certDate: "Issued: Apr 2022",
    extra_class: "",
    linkto: "",
    tooltips: ""
},
{ 
    id: 1,
    course_name: "The Fundamentals of Digital Marketing",
    organization: "https://media.licdn.com/dms/image/C4D0BAQHiNSL4Or29cg/company-logo_100_100/0/1631311446380?e=1719446400&v=beta&t=auzUbjCoE2GGTAzBjL1ss0ptopLiwpivPmlE3IYZejY",
    date: "SNDP Yogam Arts & Science College Pulpally, Wayanad, Kerala",
    certDate: "Issued: Jun 2021",
    extra_class: "",
    linkto: "",
    tooltips: ""
},


]

export default learningData