import React from 'react'
import './pages.css'
import { Container } from 'react-bootstrap';
import BadgeCard from '../componets/BadgeCard';

import certDetails from '../data/badgeData';

function Certification() {


  const certComponents = [];

  certDetails.forEach((cert) => {
    certComponents.push(
      <BadgeCard 
        certName={cert.certName}
        imageLink={cert.imageLink} 
        key={cert.id}
        linkto={cert.linkto}
        certDate={cert.certDate}
        tooltips={cert.tooltips} >
      </BadgeCard>
    );
  })
 


  return ( 
    <section id='certificate'>
    <Container className='text-light' >
        <div className='ak-skills-title my-4'>
            <h1>Badges</h1>
        </div>
        <div 
          className="
            row row-cols-2 
            row-cols-md-4 
            m-0 
            ak-skill-border-badge 
            row-gap-3 
            justify-content-between 
            py-1">
              {certComponents}
        </div>
    </Container>
    </section>
  )
}

export default Certification