
import foodoyes from "../images/foooes.png"


const experienceData = [
{ 
    id: 1,
    role: "BACHELOR OF SCIENCE: COMPUTER SCIENCE",
    company_log: "https://www.careeryojana.in/wp-content/uploads/2020/05/Calicut-University.png",
    description: "SNDP Yogam Arts & Science College Pulpally, Wayanad, Kerala",
    date_range: "June 2019 - March 2022",
    extra_class: "",
    title_class: "card-title fw-bolder fs-5",
    image_class: "img-fluid rounded-start ak-exp-img-src-edu p-2 pt-4 pt-md-0 h-100 w-100"
},
{ 
    id: 2,
    role: "HIGHER SECONDARY: COMPUTER SCIENCE",
    company_log: "https://kids.kiddle.co/images/thumb/4/43/Emblem_of_Kerala_state_Vector.svg/800px-Emblem_of_Kerala_state_Vector.svg.png",  
    description: "Jayasree HSS Kalluvayal, Pulpally, Wayanad, Kerala",
    date_range: "June 2017 - March 2019",
    extra_class: "pt-3",
    title_class: "card-title fw-bolder fs-5",
    image_class: "img-fluid rounded-start ak-exp-img-src-edu p-2 pt-4 pt-md-0 h-100 w-100"
}

]

export default experienceData