import React, { useState } from 'react';
import { Container } from 'react-bootstrap'
import './pages.css'
import learningData from '../data/learningData'


import BadgeCard from '../componets/BadgeCard';

import {useRef} from 'react';
function Learning() {


  // screen width based, minimum skill card view

  let length = 4
  let viewMore = 4

  const windowWidth = useRef(window.innerWidth);
  const widths = windowWidth.current

  // console.log('width: ',widths );

  if (widths >= 765) {
    length = 8
    viewMore = 4
    // console.log(length)
  }
  // console.log(length)

// screen width based, minimum skill card view
 

  // list lengnth
  const [itemsToShow, setItemsToShow] = useState(length);
  const totalItems = learningData.length;

  const handleLoadMore = () => {
    // Increase the number of items to display by 15
    setItemsToShow((prevItems) => prevItems + viewMore);
  };

  const displayedItems = learningData.slice(0, itemsToShow);
  let learningListComp =<>
              
                  {displayedItems.map((item) => (
                    <BadgeCard 
                      certName={item.course_name}
                      imageLink={item.organization} 
                      key={item.id}
                      tooltips={item.tooltips}
                      linkto={item.linkto}
                      certDate={item.certDate}
                    ></BadgeCard>
                  ))}

                  {itemsToShow < totalItems && (
                    <div className='text-center w-100 pt-2 py-sm-3'>
                      <button onClick={handleLoadMore} className='text-center fw-bold ak-skill-list-more btn-outline p-2' >
                        Show More
                      </button>
                    </div>
                  )}
                  
              </>


  return (
    <section id='learning'>
    <Container className='text-light'>
        <div className='ak-skills-title my-4'>
            <h1>Learning</h1>
        </div>
        <div 
          className="
            row row-cols-2 
            row-cols-md-4 
            m-0 
            ak-skill-border-badge 
            row-gap-3 
            justify-content-between 
            py-1">
              {learningListComp}
        </div>
    </Container>
    </section>
  )
}

export default Learning