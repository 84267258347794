import React from 'react'
import { Container } from 'react-bootstrap'
import './pages.css'
function Footer() {
  return (
    <div className='border-opacity-75 border-end-0 border-bottom-0 border-start-0 ak-footer-border'>
      <Container>
        <footer className='text-light text-center py-2 pb-3'>
          <small>&copy; 2002 - 2098 Akshay.  All Rights Reserved.</small>
        </footer>
      </Container>
    </div>
  )
}

export default Footer