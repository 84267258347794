
import Grafana from "../images/grafana.png"
import ubuntu from "../images/ubuntu.png"
import k8s from "../images/k8s.png"
import troubleshooting from "../images/troubleshooting.png"
import learn from "../images/learn.png"
import git from "../images/git.png"

const skillData = [

{ 
    id: 1,
    url: 'https://lightrun.com/wp-content/uploads/2022/08/company-logo-27.png',
    title: 'AWS'
},
{
    id: 2,
    url: 'https://www.gruntwork.io/assets/img/gcp-logo@2x.png',
    title: 'GCP'
},
{
    id: 3,
    url: k8s,
    title: 'Kubernetes'
},
{
    id: 4,
    url: 'https://www.pinclipart.com/picdir/big/519-5197888_terraform-terraform-icon-png-clipart.png',
    title: 'Terraform'
},
{
    id: 5,
    url: 'https://www.pinclipart.com/picdir/big/231-2312314_docker-has-been-hailed-as-a-cycle-shrinking.png',
    title: 'Docker'
},
{
    id: 6,
    url: 'https://www.pinclipart.com/picdir/big/519-5197384_gitlab-logo-svg-clipart.png',
    title: 'Gitlab'
},
{
    id: 7,
    url: ubuntu,
    title: 'Ubuntu'
},
{
    id: 8,
    url: Grafana,
    title: "Grafana"
},
{
    id: 9,
    url: 'https://www.pinclipart.com/picdir/big/570-5708783_logo-python-python-logo-clipart.png',
    title: 'Python'
},

{
    id: 10,
    url: 'https://www.pinclipart.com/picdir/big/39-399595_current-projects-github-logo-svg-clipart.png',
    title: 'GitHub'
},
{
    id: 11,
    url: 'https://www.pinclipart.com/picdir/big/135-1358798_react-js-logo-png-clipart.png',
    title: 'ReactJs'
},
{
    id: 12,
    url: 'https://www.pinclipart.com/picdir/big/61-611926_heartbeat-clipart-telemetry-prometheus-monitoring-logo-png-download.png',
    title: 'Prometheus'
},
{
    id: 13,
    url: 'https://www.pinclipart.com/picdir/big/71-717454_auto-ovpn-open-vpn-app-clipart.png',
    title: 'Pritunl VPN'
},
{
    id: 14,
    url: 'https://www.pinclipart.com/picdir/big/211-2111306_google-docs-google-sheets-clipart.png',
    title: 'Google Docs'
},
{
    id: 15,
    url: 'https://www.pinclipart.com/picdir/big/571-5716418_encrypt-https-certificate-encryption-authority-lets-lets-encrypt.png',
    title: 'Certbot SSL'
},
// https://www.pinclipart.com/picdir/big/91-918453_cloudflare-lets-encrypt-secured-by-lets-encrypt-clipart.png
{
    id: 16,
    url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
    title: 'Linux'
},
{
id: 17,
url: 'https://cdn.icon-icons.com/icons2/2699/PNG/512/nginx_logo_icon_169915.png',
title: 'NGINX'
},
{
id: 18,
url: 'https://grafana.com/static/assets/img/blog/loki.png',
title: 'Loki'
},
{
id: 19,
url: git,
title: 'Git'
},
{
id: 23,
url: 'https://freelogopng.com/images/all_img/1656733807canva-icon-png.png',
title: 'Canva'
},

{
id: 24,
url: 'https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo-shadow.png',
title: 'Bootstrap'
},
{
id: 21,
url: troubleshooting,
title: 'Troubleshooting'
},
{
id: 28,
url: 'https://cdn2.iconfinder.com/data/icons/file-formats-flat-3/780/313_-_YAML-512.png',
title: 'YAML'
},
{
id: 29,
url: 'https://about.gitlab.com/images/ci/gitlab-ci-cd-logo_2x.png',
title: 'CICD'
},
{
id: 30,
url: 'https://logospng.org/download/css-3/logo-css-3-2048.png',
title: 'CSS'
},
{
id: 31,
url: 'https://clipartcraft.com/images/html5-logo-html-5-5.png',
title: 'HTML'
},
{
id: 34,
url: "https://www.pngmart.com/files/7/SSL-Download-PNG-Image.png",
title: 'SSL'
},
{
id: 32,
url: "https://th.bing.com/th/id/OIP.xZdyEZt5ux4sLR7whA5oBQAAAA?rs=1&pid=ImgDetMain",
title: 'SSH'
},
{
id: 33,
url: "https://th.bing.com/th/id/OIP.ZvklCXr7Z-4hG4e4EglA9AAAAA?rs=1&pid=ImgDetMain",
title: 'SFTP'
},
{
id: 25,
url: 'https://th.bing.com/th/id/OIP.93ILjCMQ8zldW9HiaTvfpgHaHa?rs=1&pid=ImgDetMain',
title: 'Automation'
},
{
id: 35,
url: "https://th.bing.com/th/id/OIP.R3rJn_85ibjcWGjqRKctgQAAAA?rs=1&pid=ImgDetMain",
title: 'Cloud SQL'
},
{
id: 22,
url: 'https://th.bing.com/th/id/OIP.H24bHLLVfaO-EOjxe2KbPgHaHa?rs=1&pid=ImgDetMain',
title: 'Microservices'
},
{
id: 20,
url: 'https://yt3.ggpht.com/a/AATXAJxKJHNYse7gTasyOpRQAnNGJTlovR2SVYrXHQ=s900-c-k-c0xffffffff-no-rj-mo',
title: 'Draw.io'
},

{
id: 102,
url: learn,
title: 'learning new skills'
},
{
id: 101,
url: learn,
title: 'learning new skills'
},
{
id: 100,
url: learn,
title: 'learning new skills'
},


    
];


export default skillData;