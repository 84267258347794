

const socialData = [
{ 
    id: 1,
    icons: "bi bi-linkedin",
    visiblity: "",
    background: "#0082ca",
    profilelink: "https://in.linkedin.com/in/akshayratheesh?trk=profile-badge",
    platform: "LinkedIn"
},
{ 
    id: 2,
    icons: "bi bi-instagram",
    visiblity: "",
    background: "#ac2bac",
    profilelink: "https://www.instagram.com/ak.a.k.s.h.a.y/",
    platform: "Instagram"
},
{ 
    id: 3,
    icons: "bi bi-stack-overflow",
    visiblity: "",
    background: "#ffac44",
    profilelink: "https://in.linkedin.com/in/akshayratheesh?trk=profile-badge",
    platform: "Stack Overflow"
},
{ 
    id: 4,
    icons: "bi bi-github",
    visiblity: "",
    background: "#333333",
    profilelink: "https://github.com/AkshayRatheesh",
    platform: "GitHub"
},
{ 
    id: 5,
    icons: "bi bi-medium",
    visiblity: "",
    background: "#1f1d1c ",
    profilelink: "https://medium.com/@cloudwala",
    platform: "Medium"
},
{ 
    id: 6,
    icons: "bi bi-code ",
    visiblity: "d-none d-sm-block",
    background: "#25d366",
    profilelink: "https://g.dev/akakshay",
    platform: "Google Developer Profile"
},
{ 
    id: 7,
    icons: "bi bi-envelope ",
    visiblity: "",
    background: "#a62525",
    profilelink: "mailto:akshaypathickal@gmail.com",
    platform: "E-mail"
},
{ 
    id: 8,
    icons: "", 
    background: "#1D63ED",
    visiblity: "d-none d-sm-block",
    profilelink: "https://hub.docker.com/u/akshaypathickal",
    platform: "DockerHub"
},
  
]
  


export default socialData;