import React from 'react'
import './Badge.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


function BadgeCard(props) { 
  return ( 
    
    

<Card className='ak-badege-card-border ak-badege-card-size'>
<Card.Body data-bs-toggle="tooltip" title={props.tooltips}>
  <Card.Title>
    <div className="flex-shrink-0 d-flex justify-content-center align-items-center ">

    {props.linkto === '' ? <img src={props.imageLink}alt={props.certName}className="img-fluid rounded-circle border border-dark border-1 mj"/> : <a href={props.linkto} target='_blank'><img src={props.imageLink}alt={props.certName}className="img-fluid rounded-circle border border-dark border-1 mj"/></a>}

        {/* <img 
          src={props.imageLink}
          alt={props.certName}
          className="img-fluid rounded-circle border border-dark border-1 mj"
        /> */}

    </div> 
    </Card.Title>
        
        <h6 className="card-title text-center font-weight-bold pt-3">
          {props.certName} 
        </h6>
        <p className="card-title text-center">
          {props.certDate} 
        </p>
      </Card.Body>
    </Card>

// </div>



  )
}

export default BadgeCard
