import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './pages.css';

function Home() {
  return (
    <section className='ak-home-sec-bgc'>
    <Container className='text-light ak-home-bgc'>
      <div className="d-flex align-items-center ak-home align-middle ">
        <div className="d-flex flex-column bd-highlight mb-3">
                <div className="bd-highlight">
                    <h4 className='fw-light'> Hey, I'm </h4>
                </div>

                <div className=" bd-highlight">
                    <h1 className='display-1 fw-bold ak-home-name'>Akshay Ratheesh </h1>
                </div>

                <div className=" bd-highlight">
                    <h2 className='mt-1 mt-sm-3'> Cloud Engineer <span className='h2 fw-light'>From Kerala</span></h2>
                </div>

                <div className=" bd-highlight">
                  <a href="https://in.linkedin.com/in/akshayratheesh" target="_blank" alt="LinkedIn Messaging" title="LinkedIn Messaging" >
                    <Button variant="outline-warning ak-home-btn mt-4 btn-lg p-1 p-sm-2">LET'S CHAT</Button>{' '} 
                  </a>
                </div>

        </div>
      </div>
    </Container> </section>

  )
}

export default Home