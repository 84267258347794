import React from 'react'
import { Container } from 'react-bootstrap'
import EducationCard from '../componets/ExperienceCard'
import educationDetails from '../data/educationData.js'
import './pages.css'

function Education() {



    const educationComponents = [];

    educationDetails.forEach((education) => {
        educationComponents.push(
        <EducationCard 
            role={education.role} 
            extra_class={education.extra_class} 
            company_log={education.company_log} 
            date_range={education.date_range} 
            description={education.description} 
            title_class={education.title_class}
            image_class={education.image_class}
            key={education.id} 
        ></EducationCard>
        );
    })




  return (
    <section id='education'>
    <Container className='text-light'>
        <div className='ak-skills-title my-4'>
            <h1>Education</h1>
        </div>

        {educationComponents}

    </Container>
    </section>
  )
}

export default Education