import React from 'react'
import Card from 'react-bootstrap/Card';
import { Container } from 'react-bootstrap';
import './SkillCard.css';

function SkillCard(props) {
  return (


    <Card className="text-light ak-card-padding showhim ak-card-no-border p-4 p-sm-4 ak-skill-card-bg">
        <Card.Img className='ak-card-border ak-cards hover-shadow hover-zoom ' src={ props.url } alt="Card image" />

        <Card.ImgOverlay className='showme px-0 py-0 py-sm-2 px-sm-2 '>
            <Card.Text className='ak-card-v-cernter fw-bold h-100 text-wrap fs-6 '>{ props.title }</Card.Text>
            {/* <Card.Text>
            This is a wi
            </Card.Text>
            <Card.Text>Last updated 3 mins ago</Card.Text> */}
        </Card.ImgOverlay> 


    </Card> 

  )
}

export default SkillCard