// import React from 'react'
import "./experienceCard.css";
import React, { useEffect, useState } from "react";

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="card-text fw-bold fs-6">
        {text.length < 177 ? (
            text
        ) : (
            <>
            {isReadMore ? text.slice(0, 250) : text}
            <span
                onClick={toggleReadMore}
                className="read-or-hide"
                style={{ color: "#ffa269" }}
            >
                {isReadMore ? "...more" : " less"}
            </span>
            </>
        )}
        </p>

    );
  };




function ExperienceCard(props) {
  return (
    
        <div className="card mb-3 pb-3 pb-sm-0 p2 py-md-4 ak-exp-border" >
            <div className="row g-0 align-items-center">
                <div className="col-md-2 d-flex justify-content-md-center">
                            <div className={props.extra_class}>
                                <img 
                                    src={props.company_log} 
                                    className={props.image_class}
                                    alt="..." 
                                />
                            </div>
                    </div>
                <div className="col-md-10">
                    <div className="card-body">
                        <h5 className={props.title_class}>
                            {props.role}
                        </h5>

                        <p className="card-text"><small className="text-muted">
                            {props.date_range}
                        </small></p>

                        <ReadMore>
                            {props.description}
                        </ReadMore>
                    </div>
                </div>
            </div>
        </div>


  )
}

export default ExperienceCard