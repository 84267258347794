import React from 'react'
import { Container } from 'react-bootstrap'
import ExperienceCard from '../componets/ExperienceCard'
import experienceDetails from '../data/experienceData'

function Experience() {


  const experienceComponents = [];

  experienceDetails.forEach((experience) => {
   experienceComponents.push(
      <ExperienceCard 
      role={experience.role} 
      extra_class={experience.extra_class} 
      company_log={experience.company_log} 
      date_range={experience.date_range} 
      description={experience.description} 
      title_class={experience.title_class}
      image_class={experience.image_class}
      key={experience.id} 
      ></ExperienceCard>
    );
  })

  return (
    <section id='experience'>
    <Container className='text-light'>


        <div className='ak-skills-title my-4'>
              <h1>Experience</h1>
        </div>

        {experienceComponents}

    </Container>
    </section>
  )
}

export default Experience