import React, { useEffect, useState } from "react";
import MediumCard from "../componets/MediumCard";
import { Container, Row, Col, Button } from "react-bootstrap";

function Blog() {
  const [articles, setArticles] = useState([]);
  const [visibleItemCount, setVisibleItemCount] = useState(4); // Initial number of items to display

  useEffect(() => {
    fetch("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@cloudwala")
      .then((res) => res.json())
      .then((data) => setArticles(data.items));
  }, []);

  const handleLoadMore = () => {
    // Increment visibleItemCount by 5 (or desired increment)
    setVisibleItemCount((prevCount) => prevCount + 4);
  };

  const itemsToShow = articles.slice(0, visibleItemCount); // Slice the articles array

  return (
    <section id="blog">
    <Container className="text-light">
        <div className='ak-skills-title my-4'>
            <h1>Blogs</h1> 
        </div>

      <Row className="text-light text-center p-1">
        {itemsToShow.map((article, index) => (
          <Col key={index} xs={6} sm={4} md={3} className="p-1 p-sm-3">
            <MediumCard article={article} />
          </Col>
        ))}
      </Row>
      {visibleItemCount < articles.length && (
        <div className="text-center ">
          <Button 
            onClick={handleLoadMore}
            className="border text-dark text-wrap badge ak-btn-nav ak-nav-resume-btn btn-lg px-3 py-2"
            >Load More
          </Button>
        </div>
      )}
    </Container>
    </section>
  );
}

export default Blog;
