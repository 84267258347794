import React from 'react'
import './SkillCard.css';

function SkillList(props) {
  return (
    // <div className='text-light w-100 h-100'>
        // <ul className="list-group ak-skill-list-boder w-100 h-100 p-0 m-0 bg-primary">
            <li className="text-light list-group-item ak-skill-list-bg">{props.title}</li>
        // </ul>



    // </div>
  )
}

export default SkillList